.header-self {
    font-weight: bold;
    font-size: 25px;
    color: #303030;
}
.Selfservicetable {
    background-color: #1563ff;
    color: #fff;
    position: relative;

    .default-sort-icon {
        color: #fff !important;
        opacity: 0.8;
        padding-left: 10px;
        padding-top: 4px;
        right: auto;
    }
}
.Selfservicebcls {
    .editColumn {justify-content: space-evenly;}
    .fa-dot-circle.green{
        color:#008000a6;
    }
    .fa-dot-circle.red{
        color:#ff0000a8;
    }
    .fa-edit, .fa-trash{
        cursor: pointer;
    }
    .fa-edit{
        color: #1a73e8;
    }
    .fa-trash{
        color: #ff0000a8;
    }
}