

.breadcrumb{
    background-color: #fff;
}
#dropdown-datepicker::after{
    display: none;
}
#datepicker-menu{
    position: absolute;
    will-change: transform;
    width: 48rem;
    top: 44rem;
    left: -30rem;
    transform: translate3d(-5px, -125px, 0px);
}
.Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #4a90e2;
  }
  .Selectable .DayPicker-Day {
    border-radius: 0 !important;
  }
  .Selectable .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .Selectable .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }


  .DayPicker-Day--today {
    color: #212529 !important;
    font-weight: 400 !important;
}


.DayPicker-Day--compareToDateRange {
    background-color: rgba(255,168,0,.75) !important;
    color: white !important;
  }

  .DayPicker-Day--currentDateRange {
    background-color: rgba(0,124,246,.75) !important;
    color: #fff !important;
  }
#new-recommendation-global-filter{
  .dropdown-menu{
    top: 6px !important;
    width: 38rem;
    // width: 66rem;
    // transform:translate3d(-640px, 37px, 0px) !important;
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    z-index: 100;  
  }
}
#globalFilter{
  .dropdown-menu{
    top: 6px !important;
    width: 38rem;
    // width: 66rem;
    // transform: translate3d(-640px, 50px, 0px) !important;
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    z-index: 100;  
  }
} 
#filters input{
  font-size: 11px;
  border: 1px solid var(--kc-blue);
}
#filters .btn:hover{
  text-decoration: none;
}
#filters .btn-light,p{
  padding-left: 0;
  min-width: 8rem;
}
#filters .btn-light{
}
#filters .btn-light:hover{
  color: #4b5158 !important;
}

#filters .accordion li{
  font-size: 13px;
}
#filters{
  .dropdown-toggle::after{
    display: none;
  }
  .btn-light.focus, .btn-light:focus,.btn-light:not(:disabled):not(.disabled).active:focus, .btn-light:not(:disabled):not(.disabled):active:focus, .show>.btn-light.dropdown-toggle:focus {
    box-shadow: none;
}

}

#save-filter{
  background: none;
  border-color: #fff;
  border: none;
}
#strategy-filter > .dropdown-menu{
  transform: translate3d(-85px, 37px, 0px)  !important;
  z-index: 10; 
}

.filterRow{
  width: 100%;
  align-items: center;
  .subscription-filter.btn-cta{
    line-height: 16px;
    border: 1px solid #0f059e;
    margin-right: 10px;
  } 
  .btn-cta{
    line-height: 33px;
  }
  .form-check-input {
    position: absolute;
    margin-top: 2px;
    margin-left: -20px;
}
  .dropdown-menu{
    background: #fff;
    right: 0px;
    left: auto;

  }
  .btn-apply{
    background: #DFDFDF;
    color: #747474;
  }

}
.DayPicker:focus,.DayPicker-wrapper:focus{
  outline: none !important;
}
.DayPicker-Months{
  font-family: sans-serif;
}
#recommendationFilter, #UserNameFilter, #UseCaseFilter{
  .form-check {
    position: relative;
    display: block;
}
.form-group{
  padding: 15px;
  margin:0px;
}
.form-check{
  padding: 6px 20px;
  line-height: 30px;
  display: flex;
}
.form-check-label{
  line-height: 19px;
    font-size: 16px;
}
.filter_dropdown_wrapper{
  display: flex;
  flex-direction: column;
}
.strategy_dropdown_wrapper{
  max-height: 300px;
        overflow-y: scroll;
}
}
.globalFilters{
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  .globalFilterKeyWrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
}
#globalFilterKeys, #globalFilterValues{
  background: #FFFFFF;
  width: 100%;
  padding: 15px;
}
#globalFilterValues{
  // width: 70%;
  .form-check{
    background-color: #fff;
    padding: 9px 15px;
    font-size: 16px;
    // padding: 6px 15px;
    // font-size: 13px;
    line-height: 22px;
    color: #303030;
    border-left:none
  }
  .form-check-input {
    margin-top: 5px;
    margin-left: -10px;
}
.form-check-label{
  margin-left: 10px;
}
}
#globalFilterKeys{
  border-right: 2px solid #DFDFDF;
  // width: 30%;
  .list-group-item{
    background-color: #fff;
    padding: 9px 15px;
    font-size: 16px;
    // padding: 6px 15px;
    // font-size: 13px;
    line-height: 22px;
    color: #303030;
    border: none;
  }
  .list-group-item:hover{
    background: #F5F5F5;
    cursor: pointer;
  }
  .active{
    background: #F5F5F5;
  }
}
.global_filter_dropdown_wrapper{
  display: flex;
  flex-direction: column;
}
.global_filter_values_list{
  height: 230px;
  overflow-y: scroll;
}
.saved_filters_values_list{
  height: 300px;
  overflow-y: scroll;
  .list-group-item{
    background-color: #fff;
    padding: 9px 15px;
    font-size: 16px;
    line-height: 22px;
    color: #303030;
    border: none;
    cursor: pointer;
  }
  .list-group-item:hover{
    background: #F5F5F5;
  }
}
.selectedFilters{
  background: #FFFFFF;
  border-radius: 8px;
  .text{
    padding: 10px;
  }
  ul{
    display: flex;
    flex-wrap: wrap;
    li{
      margin:5px;
    }
  }
  .user-options{
    display: flex;
    align-items: center;
    span{
      cursor: pointer;
      font-size: 16px;
      line-height: 22px;
      color: #304FFE;
      padding: 0px 16px;
    }
    .save{
      border-right: 1px solid #000000;
    }
  }
  .filtered{
    list-style: none;
    background: #e5daf3;
    border: 1px solid #DFDFDF;
    border-radius: 4px;
    padding: 1px 5px;
    display: flex;
    align-items: center;
    .close-icon{
      cursor: pointer;
    }
    
  }
  .filtered.stratgies{
    background: #dbe5ff ;
  }
  .filtered.adoption-row{
    padding-left: 5px !important;
    padding-right: 5px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}
.save {
  .btn-light.dropdown-toggle{
    color: #304FFE !important;
    background-color: #fff !important;
    border:none !important;
    box-shadow: none !important;
}
.dropdown-menu.show{
  padding:15px;
  transform: translate3d(-170px, 45px, 0px) !important;
  width: 260px;
  background: #FFFFFF;
border: 1px solid #DFDFDF;
box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
border-radius: 8px;
}
.save-header{
  font-size: 18px;
line-height: 25px;
color: #000000;
font-weight: bold;
}
.save-subheader{
  margin-top:15px;
}
}
.new-recommendation-filter-row{
  border: 3px solid #F5F5F5;
 
}
.recommendation-filter-row{
  padding-top: 5px;
  padding-bottom: 5px;
}
 #comments-tooltip > .tooltip-inner {
  max-width: 500px;
  background-color: #303030;
  color: white;
  z-index: 999999;
  position: relative;
  border: 1px solid #303030;
}

#comments-tooltip > .tooltip-arrow {
  border-top: 5px solid #303030;
  z-index: 999999;
  position: relative;
  
}
.impact-strategy{
  .btn-cta {
    font-weight: bolder;
  }
  .dropdown-menu {
    min-width: 20rem;
  }
  .form-group {
    padding: 15px 30px;
  }
  .form-check {
    padding: 6px 0 0 15px;
  }
  .form-check-label {
    font-size: 16px;
  }
  .strategy_dropdown_wrapper {
    max-height: 300px;
    overflow-y: scroll;
  }
}
.dropdown-menu{
  .dropdown-item{
    font-size: 14px;
  }
}
.new-recommendation {
  .dropdown {
    button {
      background-color:#e2e6ea;
      border-color:#dae0e5;
    }
  }
}
.search-input {
  margin-left: 10px;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  height: 30px;
  padding: 4px 44px 4px 16px;
}
#globalfilter-tooltip {
  .tooltip-inner {
    max-width: 350px;
    background-color: #303030;
    color: white;
    z-index: 999999;
    position: relative;
    border: 1px solid #303030;
    top: 0;
    font-size: 13px;
  }
  .tooltip-arrow {
    border-top: 5px solid #303030;
    z-index: 999999;
    position: relative;
    top: 0;
    color:#303030;
  }
  .bs-tooltip-auto[x-placement^="right"] .arrow::before,
  .bs-tooltip-right .arrow::before {
    border-right-color: #303030;
  }
}
@media (max-width: 1680px){
  .alertspagestle {
    .user-options{
      span{font-size: 12px;}
      }
      .form-check-input {
        margin-top: .1rem;
      }
      #globalFilterKeys{
        .list-group-item{
          padding: 6px 10px;
          font-size: 13px;
          line-height: 16px;
        }
      }
      #globalFilterValues{  
          .form-check{
            padding: 8px 15px;
            font-size: 13px;
            line-height: 16px;
          }
          .form-check-input {
            margin-top: 2px;
        }
      }
      .global_filter_values_list{
        height: 200px;
      }
      .saved_filters_values_list{
        height: 250px;
        .list-group-item{
          padding: 8px 15px;
          font-size: 13px;
          line-height: 16px;
        }
      }
      #recommendationFilter{
        .form-check-label{
          line-height: 16px;
          font-size: 13px;
        }
        .strategy_dropdown_wrapper{
          max-height: 220px;
        }
      }
      .search-input {
        height: 24px;
      }
      
  }
}
#UserNameFilter, #UseCaseFilter, .bd-highlight {
  .btn {
    font-size: 14px;
    padding: 9px 12px;
    border : 1px solid #ddd;
  }
}