$base-color: #0F059E;
.bold{
    color: #212529;
    font-weight: 700;
}
.admin-options{
    cursor: pointer;
}
.admin-options:hover{
    transform: scale(1.1);
    transition: all .3s ease;
}
.btn-plain{
    border-radius: 0;
    margin-bottom: 0 !important;
    color:#303030 !important;
}
.btn-plain.active{
    color:$base-color !important;
    border-bottom:2px solid $base-color;
}
.btn-plain:focus {
    outline: 0;
    box-shadow: none;
}
.dashboard-button-row{
    border-bottom: 0.1px solid #ddd;;
}
#pbireport-modal {
    .title-cls {
        padding: 0 4px;
        font-weight: bold;
    }
    .close {
        font-size: 2.1rem;
    }
    label {font-weight: bold;}
    .form-control {height:calc(1.5em + .75rem + 5px);}
    
}
#adminpbi-config {
    .editColumn {justify-content: space-evenly;}
    .fa-dot-circle.green{
        color:#008000a6;
    }
    .fa-dot-circle.red{
        color:#ff0000a8;
    }
    .fa-edit, .fa-trash{
        cursor: pointer;
    }
    .fa-edit{
        color: #1a73e8;
    }
    .fa-trash{
        color: #ff0000a8;
    }
}