.paginationBttns {
  list-style: none;
  display: flex;
  justify-content: right;
}

.paginationBttns a {
  padding: 10px;
  margin-right: 8px;
  border-radius: 5px;
  border: 1px solid #2b2eff;
  color: #2b2eff;
  cursor: pointer;
}

.paginationBttns a:hover {
  color: white;
  background-color: #2b2eff;
}

.paginationActive a {
  color: white;
  background-color: #2b2eff;
}

.paginationDisabled a {
  border: 1px solid grey;
  color: #fff;
  background-color: grey;
  cursor: default;
}

.paginationDisabled a:hover {
  color: white;
  background-color: grey;
}