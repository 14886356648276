$base-color: #0056BB;
#navbarDropdown{
    cursor: pointer;
    font-size: 14px;
    line-height: 1.7;
}

.crosshairs {
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 2147483645;
  }
  
  .crosshairs.hidden {
    display: none;
  }
  
  
  .crosshairs::before,
  .crosshairs::after {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    border: none !important;
  }
  
  .crosshairs::before {
    left: -100%;
    top: -100%;
    border-right: 1px solid rgba(255, 255, 255, 0.3) !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3) !important;
  }
  
  .crosshairs::after {
    left: 0px;
    top: 0px;
    border-top: 1px solid rgba(255, 255, 255, 0.3) !important;
    border-left: 1px solid rgba(255, 255, 255, 0.3) !important;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    
  }
  
  .overlay.highlighting {
    background: none;
    border-color: rgba(0, 0, 0, 0.5);
    border-style: solid;
  }
  .nav-item-disabled:hover{
    cursor: pointer;
    background: none !important;
    color: #888 !important;
  }
  .projects_subprojects_dropdown{
    .list-group-item{
      height: 40px;
      width: 260px;
      font-size: 16px;
      line-height: 15px;
      border:none;
      color:#303030;
      cursor: pointer;
    }
    .list-group-item.active{
      background: #F5F5F5;
    }
   .manage_projects{
    color: #304FFE;
   }
   .divider{
     margin: 15px 0px;
     border: 1px solid #DFDFDF;
   }
   #projects_list{
    border-right: 2px solid #DFDFDF;
   }
   #subprojects_list{
     .active{
      font-size: 16px;
      line-height: 22px;
      background: none;
      color: #26A8F6;
     }
     &.listOfSubprojects_cls {
      height: 500px;
      overflow-y: scroll;
     }
   }
   #projects_list, #subprojects_list{
    padding: 15px 10px;

background : #fff;
   }
  }
  .project_wrapper{
    .dropdown-menu.show{
        top:5px !important;
        width: auto;
        padding:0px;
    }
    &.newprojectHeader {
      .dropdown-menu.show{
        top:10px !important;
      }
    }
    min-width: 120px;
    margin-left: 56px;
    &.newprojectHeader {
      margin-left: 0;
    }
   .project_logo_pl6 {
     padding-left: 6px;
     font-weight: bold;
   } 
}
.logout-text{
  color:$base-color;
}

.project_wrapper {
  .greatheader_logo {
    border-radius: 5px;
    height: 25px;
  }
  .retailer_header {
    color: #414bb2;
    font-size: 16px;
    font-weight: bold;
    position: relative;
    top: 2px;
  }
}
.rightmenu_pbilinks {
  position: relative;
  right: 12px;
  top: -4px;
  text-align: right;
  span {
    background-color: #652cb3;
    border-radius: 5px;
    padding: 6px 10px;
    color: #fff;
    font-size: 13px;
    font-weight: bold;
  }
  svg{
    position: relative;
    top: -3px;
    width: 12px;
  }
}

@media (max-width: 1680px){
  .alertspagestle {
      .projects_subprojects_dropdown{
        .list-group-item{
        height: 28px;
        font-size: 13px;
      }
      #subprojects_list{
        .active{
        font-size: 13px;
        }
      }
    }
    .project_wrapper{
      .dropdown-menu.show{
          top:1px !important;
      }
    }
    .greatheader_logo {
      height: 22px;
    }
    .retailer_header{
      font-size: 14px;
    }

  }
}