.header {
  &.h-36 {
    font-size: 32px;
  }
}
.newinsight-filter {
  .btn {
    border: 1px solid #0f059e;
    &:hover,&:focus, &:active{background-color: #fff;border: 1px solid #0f059e;}
    &.btn-disabled-apply {border: 1px solid #ddd;}
    padding: 10px 16px;
  }
  #new-recommendation-global-filter {.dropdown-menu {top: 15px !important}}
}
.insights-marcls{
  margin-right: 8px;
  .left_block {
    flex : 0 0 9%;
    width: 9%;
    font-size: 14px;
    .left_section {
      padding: 5px 5px 5px 6px;
      margin-bottom: 10px;
      cursor: pointer;
    }
    .sidebar-sales {
      background-color: #0056bb;
      color: #fff;
    }
  }
  .right_block{
    flex : 0 0 91%;
    width: 91%;
   .mar-tp{ 
    margin-top: 60px;
   }
  }
}

.top-borderbtm {
  border-bottom: 2px solid #dee2e6;
}
.titlename {
  font-size:18px;
  font-weight: bold; 
}
.togglebtn {
  .selectedbtn {
    background-color: #0056b3;
    color: #fff;
    cursor: pointer;
  }
  &:first-child {
    margin-right: 2px;
  }
  .btn {
  border : 1px solid #0056b3;
  cursor: pointer;
  padding: 6px 20px;
  }
}
.downloadfile {
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  color: #0056bb;
  svg {position: relative; top: -2px;color: #13a68a;}
}
.insighttable {
  margin: 20px 30px; 
  .bi-caret-down-fill, .bi-caret-up-fill {
    position: relative;
    top: -2px;
  } 
}

.insighttable, .insightpricetable{
  .newValueformat {
    cursor: pointer;
    color: #0056b3;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
      color: #0056b3;
    }
  }
  .clr-grn {
    color: #0ca789;
  }

  .clr-rd {
    color: #FF3333;
  }

  .table th,
  td {
    font-size: 12px;
    padding: 10px 5px !important;
  }

  .crisplink {
    color: #842295;
    cursor: pointer;

    svg {
      width: 12px;
      height: 12px;
      position: relative;
      right: 2px;
    }
  }
}

.topmoverbdycls td, .toplooserbdycls td{
  &.editdelete-cls {
    padding: 0 !important;
  }
  .ed-clr, .dl-clr { 
    border: none;      
  }
  .dl-clr {
    border-left: 1px solid #ddd;
  }
}

.titletp {
  font-size: 22px;
  font-weight: bold;
}
.table { 
  .topMoverscls {
    svg {
      color: #000;
      position: relative;
      top: 6px;
    }
  }
}
.topMoverheader {
  display: flex;
  justify-content: space-between;
  .topgainorloser {
    font-size: 18px;
    font-weight: bold;
    position: relative;
    bottom: 6px;
  }
}
.modal {
  padding: 10px;
  #newinsight-modal {
    min-width: 100%;
    max-width: 100%;
    .container {
      min-width: 100%;
      max-width: 100%;
    }
    .report {
      height: 90rem;
    }
    
    @media  (max-width: 1404px){
      .report {
        height: 36rem;
      }
    }
    
    @media (max-width: 1580px) and (min-width: 1405px){
      .report {
        height: 41rem;
      }
    }
    @media (max-width: 1680px) and (min-width: 1581px){
      .report {
        height: 48rem;
      }
    }
    @media (max-width: 1880px) and (min-width: 1681px){
      .report {
        height: 52rem;
      }
    }
    @media (max-width: 1980px) and (min-width: 1881px){
      .report {
        height: 60rem;
      }
    }
    
    
  }
}
.ht_overflow {
  .react-bs-container-body {
    height: 500px !important;
  }
}

.topMoversTablecls { 
  .top_moverstablescls {   
      height: 500px;
      overflow-y: scroll;     
  }
  .sticky-top {
    z-index: 10;
  } 
  .table td, .table th {
    font-size: 12px;
    padding: 5px !important;
    vertical-align: inherit !important;
    text-align: center;
  }
  .topMvtable {
    .default-sort-icon {
        opacity: 0.8;   
        padding-left: 10px;             
        padding-top: 4px;
        right: auto;
        position: relative;
    }
  }
  .img_cls {
    width: 90px;
    img {
      height: 80px;
      width: 80px;
    }
  }
  tbody .skutitle-cls {
    padding: 0 !important;
    .title_cls {
      border-left: 1px solid #ddd !important;
    }
  }
  .img_cls, .title_cls {
    border : none;
  }
  .clr-grn {color: #0ca789;}
  .clr-rd {color: #FF3333;}
  
}

.topMoversTablecls, .insightpricetable{ 
  .bi-caret-down-fill, .bi-caret-up-fill {
    position: relative;
    top: 3px;
  } 
}

.sales-predict-pbi, .sentiment-abilitytab {
  .container-fluid {
    padding-right: 0;
  }
  .customCol {
    padding-right: 8px;
  }
}

.tabbtn {
    border : 1px solid #0056b3;
    background-color: #fff;
    cursor: pointer;
    color: #315dbd;
    font-size: 12px;
    padding: 4px;
    margin-right: 15px;
    border-radius: 5px;
    font-weight: bold;
    line-height: normal;
    height: 41px;
    width: 115px;
    &.tabpricing {
      padding: 4px 8px;
      width: auto;
    }
    &:focus{
      outline: none;
    }
    &:last-child {
      margin-right: 40px;
    }
    &.selectedbtn {
      background-color: #0056b3;
      color: #fff;
    }

}
.ratingreview {
  margin-left: 90px;
  .w-g {
    width: 79%;
  }
  .ratingclr {
    color: #0056bb;
    font-weight: bold;
    padding-bottom: 15px;
  }
  .ratingoverall {
    color: #0056bb;
    font-size: 14px;
    font-weight: bold;
    margin-top: 10px;
    width: 38%;
  }
  .ratingdesc {
    font-size: 11px;
    width: 70%;    
  }
  .clrimgcls {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 19.5%;
    padding-right: 25px;
    margin-bottom: 88px;
    padding-left: 8px;
  }
  .clrimgcls-cls {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 20.5%;
    margin-bottom: 54px;
    padding-right: 6px;
  }
  &.graph-mar {
    margin-top: 50px;    
    margin-right: 40px;   
    .recharts-legend-wrapper {
      left: 0 !important;
      top: 10px !important;
    }
  }
}

.star-rating {
  display: inline-block;
  background-color: #fff;
  line-height: normal;
  .fas {
    font-size: 15px;
    padding-left: 1.5px;
    padding-right: 1.5px;
  }
  .star-full, .star-half {
    color: gold;
  }
  .star-empty {
    color: #ccc;
  }
  &.ratingwidh, &.rtw-25 {
    display: flex;
    flex-wrap: wrap;
    .fas {
      flex: 0 0 auto;
      padding-right: 0;
      padding-left: 0;
    }
  }
  &.rtw-25 {
    .fas {
      font-size: 10px;
    }
  }
}

.ratingsummary {
  color: #0056bb;
  font-weight: bold;
  padding-bottom: 15px;
}
.ratingsummarycount {
  .card {
    font-size: 14px;
  }
}
.sentimentbdycls {
  background-color: #10069f;
  color: #fff;
}
.sentimentcls {
  tr:nth-child(even) {background-color: #e7e7f0;}
  tr:nth-child(odd) {background-color: #ccccdf;}
}
.insighttable,.insightpricetable {
  .react-bs-table {
    table {
      tr:first-child {
        th {
          background-color: #0056bb;
          color: #fff;
        }
      }
      td {
        word-wrap: break-word;
        white-space: inherit;
      }
    }
  }
}
.loading_insights {
  height: 50vh;
}
.f-24 {
  font-size: 24px;
}
.f-20 {
  font-size: 20px;
}
.f-15 {
  font-size: 15px;
}
.f-13 {
  font-size: 13px;
}
.f-12 {
  font-size: 12px;
}

.pricingheader_title {
  font-size: 2.2rem;
}

.fulltextbtm {
  margin: auto;
  margin-top: 26px;
  text-align: center;
  span {
    color: #898989;
    margin-right: 10px;
  }
}

.insightpricetable {
  .td-column-increase,.td-column-decrease,
  .td-column-equal,.td-column-noprice {
    padding: 0;
  }
  .td-column-increase {
    background-color: #a4de6c;
  }
  .td-column-decrease {
    background-color: #ffb3b3;   
  }
  .td-column-equal {
    background-color: #919695;
  }
  .td-column-wp {
    background-color: #ffd11a;
  }
  .td-column-noprice {
    background-color: #df9e4e;
  }
  .tdstatuscls {
    padding: 10px 5px;
  }
  table {
    td {vertical-align: middle;}
  }

  
  
}
.tablerow_first {
  background-color: #0056bb;
  color: #fff;
}
.table_center {
  th,td {
    text-align: center;
  }
}

#pricetable-tooltip {
  .tooltip-inner {
    max-width: 190px;
    background-color: #007bff;
    color: white;
    z-index: 999999;
    position: relative;
    border: 1px solid #007bff;
    top: 0;
    font-size: 12px;
  }
  .tooltip-arrow {
    border-top: 5px solid #007bff;
    z-index: 999999;
    position: relative;
    top: 0;
    color:#007bff;
  }  
}
.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::before {
  border-right-color: #007bff !important;
}
.price-top-cont {
  .table th,
  td {white-space : normal}
  &.PriceTopTable {
      .react-bs-container-body {
      height: 500px !important;
    }
  }
}
.specificDate {
  .date-range {
    padding: 5px;
  }
  #dropdown-datepicker {
    background: #fff;
    border: 1px solid #0f059e;
    border-radius: 6px;
  }
  #datepicker-menu {    
    border: 1px solid #0f059e;
    border-radius: 30px;
    left: -20px !important;
    width: 225px;
  }

  .month-selector{
    span[disabled] {
      color: #ddd;
      pointer-events: none;
    }
  }
    
  
}
.table-striped tbody {
  tr:nth-of-type(odd) {
    background-color: #ccccdf;
  }
  tr:nth-of-type(even) {
    background-color: #e7e7f0;
  }
}
.report-style-class{
  height: 48rem;
}
.tablecls_scrl {
  Table {
    min-width: 1300px;
  }
  overflow: scroll;
  height: 480px;
  .sticky-top {
    z-index: 20;
  }
  .second_tblrow {
    background-color: #fff;
  }
}






