.strategyRow{
    color:#2b333b;
    .name{
        font-weight: 600;
    }
}
#strategy_wrapper{

    .fa-dot-circle.green{
        color:#008000a6;
    }
    .fa-dot-circle.red{
        color:#ff0000a8;
    }
    .fa-edit, .fa-trash{
        cursor: pointer;
    }
    .fa-edit{
        color: #1a73e8;
    }
    .fa-trash{
        color: #ff0000a8;
    }
}

.editColumn{
    display: 5rem;
    justify-content: space-around;
}