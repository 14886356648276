#manageSuscriptionsNav{
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 300;
    background-color: #fff;
    box-shadow: 2px 1px 8px 0 #e9eaeb;
    padding: 2.4rem;
    .fas{
        font-size: 1.5rem;
        margin-right: 1rem;
        color: #8b8f93;
        fill: #8b8f93;
    }
    .btn{
        background-color: #aaadb1;
        color: #fff;
        padding: 12px 32px;
        font-weight: 600;
        border-radius: 6px;

    }
}
#subscription_wrapper{

   h3{
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    
    color: #000000;
   }
    .marginTopSubscription{
        margin-top: 6rem;
        padding: 1rem;
        .save_wrapper{
            margin: 10px 0px;
            display: flex;
            justify-content: flex-end;
            .btn{
                color: #fff;
                background-color: #1a73e8;
                padding: 10px 25px;
                font-weight: 600;
                border-radius: 6px;
            }
        }
    }
    .selected_option{
        color: #26A8F6;
    }
    .option{
        display: flex;
        padding: 10px 15px;
        justify-content: space-between;
        font-size: 16px;
    }
    .option_span{
        color: #4b5158;
        fill: #4b5158;
        font-weight: 600;
    }
    .list_wrapper{
        background-color: #fff;
        margin-top: 1.6rem;
        height: 50rem;
        overflow: auto;
    }
    .unsubscribe_button{
        margin-top: 2.5rem;
    }
    .exists{
        color: #26A8F6;
    }
    .selectedFilters{
        background:#f9f9f9 !important;
    }
    .option_hover:hover{
        background-color: #e9eaeb;
        color: #222;
        font-weight: 500;
        cursor: pointer;
        .user_option{
            display: block;
            color: #1a73e8;
            margin:0px;
        }
    }
    .user_option{
        display: none;
    }
    .unselected_option,.selected_option{
        padding:2px;
    }
    .subscribed{
        background-color: #fff;
        padding: 0px 25px;
        h4,h5{
            color: #444444e0;
        }
    }
    .subscribed:hover{
        .subscription_button_group{
            visibility: visible;
        }
    }
    .subscription_desc{
        padding: 10px 10px 10px 25px;
        .row{
            padding: 1rem !important;
        }
    }
    .blue_button{
        color: #1a73e8;
    }
    .grey_button{
        color: #777;
    }
    button{
        font-weight: 600;
    }
    .subscription_button_group{
        visibility: hidden;   
    }
    .action-items{
        display: none;
    }
    .filter_display_wrapper{
        .add_filter_wrapper{
             display: none;
        }
        .filtered i{
            display: none !important;
        }
    }
}

