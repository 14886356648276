$base-color: #0056BB;
.recommendations-table{
    .dropdown-menu>li>a {
        display: block;
        padding: 3px 20px;
        clear: both;
        font-weight: 400;
        line-height: 1.42857143;
        color: #333;
        white-space: nowrap;
    }
    .dropdown-item{
        display: list-item;
        text-align: -webkit-match-parent;
    }
}
.channel_td{
    min-width: 8rem;
}
.btn-active{
    background-color: $base-color  !important;
    color: #fff !important;
}
.btn-action{
    margin: 0px 10px;
    color: $base-color ;
    border:1px solid $base-color ;
}
.btn-action-taken{
    background-color: $base-color;
    color:#fff;
    border: 1px solid $base-color;
}
.btn-approve{
    background-color: #F3AC00;
    color:#fff;
    border: 1px solid #F3AC00;
}
.btn-open{
    background-color: #9C9A9A;
    color:#fff;
    border: 1px solid #9C9A9A;
}
.btn-removed{
    background-color: #F5F5F5;
    color:#303030;
    border: 1px solid #777;
}
#recommendationFilter, #UserNameFilter,#UseCaseFilter{
    .dropdown-menu.show {
        display: block;
        width: 20rem;
        left: auto !important;
        z-index: 100;
    }
    .fa-trash:hover{
        color:#ff0000;
    }
    .fa-trash{

        position: absolute;
        right: 0px;
        color: #ff000054;
        top: -6px;
        display: none;
    }
    display: flex;

    .btn-light.focus, .btn-light:focus,.btn-light:not(:disabled):not(.disabled).active:focus, .btn-light:not(:disabled):not(.disabled):active:focus, .show>.btn-light.dropdown-toggle:focus {
        box-shadow: none;
    }
    .dropdown-toggle::after{
        display: none;
    }

   
}
#listOfStrategies:hover{
    .fa-trash{
        display: block !important;
    }
}
#listOfStrategies{
    position: relative;
    padding:5px 12px;
background: #fff;
background-color: white;
color: #8b8f93;
border: 1px solid #e9eaeb;
border-radius: 24px;
cursor: pointer;
font-size: 12px;
margin: 5px;
display: flex;
align-items: center;
}
.alert_summarytable {
    .default-sort-icon {
        position: relative;
        left: 4px; 
        top: 2px; 
    }
}