.big_header{
    font-style: normal;
    font-weight: 900;
    font-size: 240px;
    line-height: 327px;
    text-align: center;
    color: #CCCCCC;
}
.big_text{
    font-weight: normal;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    color: #303030;
}