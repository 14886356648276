#version_history_content_wrapper{
    padding:0px 40px 40px 270px !important;
}
#versions_wrapper{
    .side-nav{
        top:147px;
    }
    #versions_sidebar{
        max-width: 230px;
        margin-top: 70px;
        
    }
    .new_version_button_wrapper{
        padding: 10px 15px;
        background: #fff;
    width: 230px;
    position: fixed;
        .btn{
            width: 100%;
        }
    }
}