.deleteWidget{
    position: absolute;
    right: 0;
    top: -10px;
}
.builder-border-default{
  border: 1px dashed #ddd;
}
.dropGround{
    .row{
        margin:0;

    }
    div{
      .activeDiv{
        background: #00000030;
          border-color: black;
          border-style: solid;
      }

      .row{
        &:hover{
          border-style: solid;
          border: 1px solid #3737371c;
          background: #f3f3f3;
        }
      }
      .customCol{
        background-color: #eaeaea;
        border: 1px dashed #ddd;
        &:hover{
          border-style: solid;
          border-color: #c1c1c1;

        }
      }
    }
}
.deleteWidget{
    .fa-trash{
        color: #777;
    }
    .fa-trash:hover{
        transform: scale(1.1);
        transition: all .2s linear;
    }
}
.tabsConfigFooter{
  position: absolute;
  bottom: 4px;
  border-top: 1px solid #d5dfe6;
  left: 4px;
  right: 4px;
  padding: 6px;
  display: flex;
  justify-content: space-between;
 button{
   margin-left: 4px;
margin-right: 4px;
 }
 .btn-cancel{

 }
 .btn-done{

 }
}
.popover{
     position: 'absolute';
     zIndex: '2';
   }
.cover{
     position: 'fixed';
     top: '0px';
     right: '0px';
     bottom: '0px';
     left: '0px';
   }

.note-list{

}
.note-list li{
      list-style-type: circle;
}



.dropGround {
  .activeElement{
           &:before{
             content: '';
              border: 2px groove #F44336 !important;
              position: absolute;
              left: 0;
              bottom: 0;
              height: 100%;
           }

      }

}
.side-nav-block-type{
  font-weight: 400;
margin-bottom: 10px;
font-size: 16px;
text-transform: capitalize;
padding: 4px;
background-image: linear-gradient(to bottom, #b2d3e2, #deeaf6);
border-radius: 6px;
padding-left: 10px;
}
.active-window-type{
  color: #4E8ABE;
background-color: #d9e6ef;
*background-color: #D5E4EB;
background-image: linear-gradient(to bottom, #d5e4eb, #deeaf6);
background-repeat: repeat-x;
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffd5e4eb', endColorstr='#ffdeeaf6', GradientType=0);
box-shadow: inset 0 0 3px rgba(0, 61, 99, 0.53);
}
.Desktop, #Desktop{
  width:100%;
  margin: auto;
}
.Mobile, #Mobile{
  max-width:768px;
  width:100%;
  margin-left: auto !important;
  margin-right: auto !important;
}
.Tablet, #Tablet{
  max-width:1000px;
  width:100%;
  margin-left: auto !important;
  margin-right: auto !important;
}

.report{
  overflow: hidden;
  position: relative;
    iframe {
       border: 0;
       height: 100%;
       left: 0;
       position: absolute;
       top: 0;
       width: 100%;
    }
}
.loader-wraper{
  width: 100%;
background: #ffffffad;
position: absolute;
left: 0;
right: 0;
top: 0;
height: 100%;
z-index: 1;
  .loader{
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.long_row{
  height: 41rem;
  align-items: center;
}