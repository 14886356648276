#IdleTimeOut-modal {
    .modal-content {top: 80px;} 
    .modal-title {font-weight: bold;}
    .modal-body {
        background-color: #e6e6e6;font-weight: bold;font-size: 16px;padding: 15px 45px;
        .idle-plr{padding-left: 80px;padding-right: 80px;}
    }
    .modal-footer {
        justify-content: center;
        button {background-color:#414bb2;color: #fff;font-weight: bold;padding: 6px 50px}
    }
   
}