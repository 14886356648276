
    .full-screen-height{
        height: 95vh;
    }
    .wrappers-with-min-height{
        .tile-list{
            min-height: 300px;
        }
    }


    .tabList {
      .form-group{
      padding: 10px;
      background: #fff;
      border: 1px solid #ced4da;
      .form-check{
            padding-left: 1.85rem;
      }
      .form-check-input{
            margin-left: -1.85rem;
      }
    }
  }
