#circle,
#inside-circle,
.table-responsive-chart,
#horizontal-chart , .progress-bars, .progress-bar-col{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
}

.VictoryContainer{
  height: 95% !important;
}
#circle > * ::after,
::before {
  box-sizing: unset !important;
}

#circle > * {
  box-sizing: unset !important;
}



.custom-table-grid {
  border-collapse: separate;
  border-spacing: 4px 4px;
}
.custom-table-grid tbody {
  background: #f5f5f5;
}
.custom-table-grid thead tr th {
  padding: 4px !important;
  font-size: 12px;
}
.custom-table-grid thead tr th:first-child {
  font-weight: bold;
  font-size: 14px;
}
.custom-table-grid tbody tr td:first-child {
  line-height: 15px !important;
  color: #000;
  padding: 2px !important;
  font-size: 14px;
  
}
.custom-table-grid tbody tr td:not(:first-child) {
  line-height: 15px !important;
  background: #2ebbe6;
  color: #ffff;
  text-align: center;
  padding: 4px !important;

}

#user-dropdown > .btn,
#date-dropdown {
  font-weight: bolder !important;
}

#adoption-strategy > .btn {
  border-color: #f5f5f5 !important;
  border-width: 5px;
  border-radius: 5px;
  font-weight: bolder;
}

#vertical-text {
  transform: rotate(180deg);
  transition: transform 1s ease-in-out;
  writing-mode: tb-rl;
  font-weight: bolder;
}

.headerDivider {
  border-left: 2px solid #b6c0c9;
  height: 200px;
}
#adoption-filter {
  background: #f5f5f5 !important;
  margin-right: 15px !important;
  padding: 3px !important;
}

#no-data-available {
  
  text-align: center;
  margin-top: 5%;
  justify-content: center;
  display: flex;
}

.custom-selectable
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: #4a90e2;
}
.custom-selectable .DayPicker-Day {
  border-radius: 0 !important;
}
.custom-selectable .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
.custom-selectable .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

#strategy-filter>.btn{
  background: #F5F5F5 !important;
}

.recommendation-global-filter.btn{
  background: #F5F5F5 !important;

}
.alert_impact{margin-bottom:20px;}
.alert_impact .col {
  font-size: 25px;
  font-weight:bold;
  margin-right:22px;
  padding: 30px 8px 5px;
  text-align:center;
  word-wrap: break-word;
  width: 80px;
}
.alert_summarytable .react-bs-table table td, .alert_summarytable .react-bs-table table th {
 word-break:break-word;
 white-space: initial;
}
.alert_summarytable .react-bs-table table td:first-child, .alert_summarytable .react-bs-table table th:first-child,
.alert-bysummary .react-bs-table table td:nth-child(2), .alert-bysummary .react-bs-table table th:nth-child(2) {
  padding-left: 6px !important;
 }
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.alert_summarytable .caret[style] {
  margin-left: 2px !important;
}

.dropup .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}
.estimate-border {
  border: 1.4px solid #6a6a6a;
  .border-estimateval {
    border: 1.4px solid #989898;
  }
}
.currencycls {
  margin-top: 9px;
  font-size: 14px;
  .form-group {
      border: 1px solid #ddd;
      padding: 8px 10px;
      border-radius: 4px;
      label {
        font-weight: bold;
      }
  }
  .currencyClr {
    input {accent-color: #0f059e;}
    label {color: #0f059e;font-weight: bold;}
  }
}
.popyoycls {
  font-size: 12px;
  justify-content: space-between;
  display: flex;
  padding-top: 15px;
}
.clr-grn {
  color: #0ca789;
  svg {
    top: -1px;
    position: relative;
  }
}
.clr-rd {
  color: #f33;
}
.engagementsummary_cl {
  .border-estimateval {
    margin-right: 60px;
  }
}
.pagination span {
  float: none;
  padding: .55rem .75rem;
  border: none;
  
}

.page-item.disabled .page-link {
  color: #000;
}
.pagination .page-item a {
  color: #fff;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  border-color: #007bff;
  margin-left: 0;
  margin-right: 1px;
}