


.new-recomm {
  background: #cbcbcb;
}
.new-recomm .new-recomm-container{
  display :table;
}
.recomm-header.recomm-dynamiccol > div.location_block,.recomm-locations.recomm-dynamiccol div.locations > div.recomm-col.location_block,.recomm-header.recomm-dynamiccol > div,.recomm-locations.recomm-dynamiccol div.locations > div.recomm-col{min-width:350px;max-width:350px;}
.recomm-locations.recomm-dynamiccol div.locations > div.recomm-col.txt-right-aligned{align-items: stretch;}
.recomm-header.recomm-dynamiccol > div.strategies,.recomm-row.recomm-dynamiccol > div.recomm-locations .locations .recomm-col.strategies{min-width:400px;max-width:400px;}
.recomm-header.recomm-dynamiccol > div.first-col, .recomm-row.recomm-dynamiccol > div.recomm-product{min-width:400px;max-width:400px}
.recomm-dynamiccol .recomm-locations{margin-left: 400px;}
.new-recomm .recomm-dynamiccol .product-box{max-width: 360px;}
.recom_horizontal.recomm-dynamiccol{min-width:400px;max-width:400px}

.recomm-header.recomm-dynamiccoldc > div.location_block,.recomm-locations.recomm-dynamiccoldc div.locations > div.recomm-col.location_block,.recomm-header.recomm-dynamiccoldc > div,.recomm-locations.recomm-dynamiccoldc div.locations > div.recomm-col{min-width:480px;max-width:480px;}
.recomm-locations.recomm-dynamiccoldc div.locations > div.recomm-col.txt-right-aligned{align-items: stretch;}
.recomm-header.recomm-dynamiccoldc > div.strategies,.recomm-row.recomm-dynamiccoldc > div.recomm-locations .locations .recomm-col.strategies{min-width:480px;max-width:480px;}
.recomm-header.recomm-dynamiccoldc > div.first-col, .recomm-row.recomm-dynamiccoldc > div.recomm-product{min-width:500px;max-width:500px}
.recomm-dynamiccoldc .recomm-locations{margin-left: 500px;}
.new-recomm .recomm-dynamiccoldc .product-box{max-width: 460px;}
.recom_horizontal.recomm-dynamiccoldc{min-width:500px;max-width:450px}

.recom_horizontal{padding-left:35px;min-width:380px;max-width:380px}
.recomm-header, .recomm-locations div.locations{
    display: flex;
    background-color: #efefef;
    align-items: stretch;
}
.product-name{
  white-space: pre-wrap !important;
}

.recomm-header, .recomm-locations div.col-title{
  cursor: pointer;
} 
.recomm-header > div, .recomm-locations div.locations > div.recomm-col{
    flex-shrink: 1;
    flex-basis: 0;
    text-align: center;
    border: 1px solid #efefef;
    width: 0;
    flex-grow: 1;
    flex-direction: column;
    padding: 10px;
    line-height: 1;
    border-right-color: #d1d1d1;
    color: #303030;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: stretch;
    padding: 10px;
    min-width: 200px;
    max-width: 200px;
    background: #efefef;
        position: relative;
}
.recomm-locations div.locations > div.recomm-col{
font-weight: normal;
}
.recomm-locations div.locations > div.recomm-col.txt-right-aligned{
  align-items: flex-end;
  word-break: break-all;
}

.recomm-header > div.strategies, .recomm-row > div.recomm-locations .locations .recomm-col.strategies{
min-width: 340px;
max-width: 340px;
}
.recomm-row > div.recomm-locations .locations .recomm-col.strategies{
flex-wrap: wrap;
flex-direction: row;
}
.recomm-row > div.recomm-locations .locations .recomm-col.strategies.expanded{
  justify-content: flex-start;

}

.recomm-header > div.first-col {
  text-align: left;
  padding-left: 48px;
}
.recomm-header > div.first-col, .recomm-row > div.recomm-product{
min-width: 380px;
max-width: 380px;
flex: 1 0 380px;
position: sticky;
left: 0;
z-index: 2;
}
.recomm-header{
  position: sticky;
  top:0px;
  z-index:10;
}
.fetchingMoreItem{
  background: #fff;
  text-align: center;
  padding: 10px;
  color: #1a73e8;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}
.fetchingMoreItem .fetechMoreImg{
  width: 100px;
    display: block;
    margin: auto
}
.recomm-header > div.first-col{
background-color: #efefef;
}
.recomm-row > div.recomm-product{
background: #fff;
    height:400px;
        border: 1px solid #efefef;
}

.recomm-locations .locations {
  align-items: baseline;
}

.recomm-locations {
  position: absolute;
      margin-left: 380px;
      height: 410px;
      background: #fff;
}
.recomm-locations-overflow {
  overflow-y: auto;
}

.recomm-row {
  display: flex;
  width: 100%;
  position: relative;
  border-bottom: 1px solid #cbcbcb;
  padding-bottom: 1px;

}
.recomm-row > div.recomm-col{

}

.recomm-locations div.locations > div.recomm-col{
background: #fff;
    align-items: baseline;
}

.recomm-locations div.locations > div.recomm-col .form-check {
padding-left: 2rem;
    text-align: left;
    font-size: 14px;
}
.recomm-locations div.locations > div.recomm-col .form-check-input {
  position: absolute;
  margin-top: .1rem;
  margin-left: -1.25rem;
  margin-left: 0;
left: 0;
}



.recomm-product{
position: relative;
}



.new-recomm .product-box{
padding: 16px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #ffff;
    height: 100%;
    max-width: 370px;
}
.new-recomm .product-box .product{
text-align: center;
}

.new-recomm .product-name{
font-size: 16px;
font-weight: 700;
padding-top: 12px;
padding-bottom: 6px;
}
.new-recomm .product-Desc{
color: grey;
font-weight: 500;
}
.new-recomm .product-Links ul {

    list-style: none;
    padding: 0;
    padding-top: 14px;
    text-align: center;

}

.new-recomm .product-Links ul li{

    display: inline-block;
    padding-left: 16px;
    padding-right: 16px;

}
.new-recomm .product-Links ul li a{
font-weight:500;

}

.new-recomm .product-Links ul li:first-child{
border-right: 2px solid #b6b6b7;
}



.new-recomm .recomm-name{
  display: inline-block;
  border: 2px solid #4540b6;
  padding: 2px 8px;
  border-radius: 7px;
  margin-right: 10px;
  margin-bottom: 2px;
  cursor: pointer;
}
.new-recomm .recomm-name2{
display: inline-block;
background: #4540b6;
color: #fff;
padding: 2px 8px;
border-radius: 7px;
margin-right: 10px;
margin-bottom: 2px;
cursor: pointer;
}
.full-screen-mode-table-row::-webkit-scrollbar {
  width: 14px;
  height: 14px;
  border-radius: 11px; 

  background: #e0dede;
}
.full-screen-mode-table-row::-webkit-scrollbar-thumb {
  border-radius: 11px; 
  background: #979797;
}


.full-screen-mode-table-row::-webkit-scrollbar-track {
  box-shadow: none;
  border-radius: 11px;    
}

.new-recomm::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.new-recomm::-webkit-scrollbar-thumb {
  border-radius: 11px; 
  box-shadow: inset 0 0 6px #979797;
}

.new-recomm::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #979797;
  border-radius: 11px;
}
.default-sort-icon{
position: absolute;
right: 4px;
opacity: 0.3;

}
.default-sort-icon.active{
opacity: 1;
}

.new-recomm .product-img{
height: 172px;
max-width: 172px;
width: 100%;
}

.recommendation-model-strategy-name{
display: inline-block;
padding: 6px;
padding-left: 20px;
padding-right: 20px;

text-align: center;
color: #1a73e8;
margin: 1px;
border-top-left-radius: 8px;
cursor: pointer;
border-top-right-radius: 8px;

}
.recommendation-model-strategy-name.active-strategy, .recommendation-model-strategy-name:hover, .recommendation-model-strategy-name:active, .recommendation-model-strategy-name:focus{
background: #1a73e8;
color: #fff;

}
#new-recommendation-header-mar{
padding-bottom: 0;
  border-bottom: 2px solid #1a73e8;
  margin-bottom: 0;
}
.row.new-recommendation-header-mar{
margin-bottom: -1px !important;
}


#recommendations-modal-new .modal-content {
  border: 2px solid #1a73e8;
  border-radius: 1.3rem;

}
.sticky-wrapper {

  max-height: 900px;
  }
  
  .sticky  {
      max-height: calc(100vh - 80px);
  }
  .new-recomm#sticky::-webkit-scrollbar {
      width: 10px;
      height: 10px;
      background: #EBEBEB;
      border-radius: 11px;    
  }
  .new-recomm#sticky::-webkit-scrollbar-thumb {
      border-radius: 11px; 
      background: #c4c3c3;
  }
  
  
  .new-recomm#sticky::-webkit-scrollbar-track {
      box-shadow: none;
      border-radius: 11px;    
  }
  
  .product-sku{	
    font-size: 12px;	
        color: grey;	
        display: flex;	
        flex: 1 1;	
        width: 100%;	
        justify-content: space-between;	
        background: whitesmoke;	
        padding: 3px;	
        padding-left: 10px;	
        padding-right: 10px;
        flex-wrap: wrap;	
    }	
    .recomm-header > div.location_block, .recomm-locations div.locations > div.recomm-col.location_block{	
    min-width: 320px;	
    max-width: 320px;	
    }

    .recomm-header > div.strategies.all-collapsed{	
      min-width: 0px;	
      max-width: 0px;	
      width: 0;	
      padding: 0;	
      border: 0px;	
      position: relative;	
      }	
      .recomm-header > div.strategies.all-collapsed .collapsed-icon{	
      position: absolute;	
        z-index: 1;	
        left: 0;	
        width: 30px;	
        background: #dddcdc;	
        top: 0;	
        bottom: 0;	
        right: 0;	
        border-right: 1px solid #d1d1d1;	
      }	
      .recomm-header > div.strategies.all-collapsed .collapsed-icon .default-sort-icon{	
      position: absolute;	
      top: 50%;	
      left: 50%;	
      transform: translate(-50%,-50%);	
      }
      .product-sku{	
        font-size: 12px;	
            color: grey;	
            display: flex;	
            flex: 1 1;	
            width: 100%;	
            justify-content: space-between;	
            background: whitesmoke;	
            padding: 3px;	
            padding-left: 10px;	
            padding-right: 10px;
            word-break: break-word;	
        }	
        .product-sku div{width:calc(100% - 20px);flex: 1 0 33%;}
        .recomm-header > div.location_block, .recomm-locations div.locations > div.recomm-col.location_block{	
        min-width: 320px;	
        max-width: 320px;	
        }
        @media(min-width:1440px){
          .width-auto .recomm-header > div.location_block, .width-auto .recomm-locations div.locations > div.recomm-col.location_block{
            max-width: 491px;
            min-width: 491px;
          }
          .width-auto .recomm-header > div, .width-auto .recomm-locations div.locations > div.recomm-col{
            max-width: 491px;
            min-width: 491px;
          }
          .width-auto .recomm-header > div.first-col, .recomm-row > div.recomm-product{
            min-width: 380px;
        max-width: 380px;
          }
        
          .width-auto  .recomm-header > div.strategies, .width-auto  .recomm-row > div.recomm-locations .locations .recomm-col.strategies{
            min-width: 491px;
        max-width: 491px;
          }
        
          .width-auto .recomm-header > div.strategies.all-collapsed{
            min-width: 0px;
        max-width: 0px;
        width: 0;
          }
        }

        .outer-wrapper{
        height: 291px ;
        background-color: white;
        margin-bottom: 30px ;
        }

      .full-screen-outer-wrapper{
          height: 100vh;
          background-color: white;
          margin-bottom: 30px;
          position: fixed;
          z-index: 620;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: flex;
          width: 100%;
          overflow: auto;
          flex-direction: column;
        }

      .full-screen-mode-table{
        overflow: unset;
      }

      .full-screen-mode-table-row{
        overflow: scroll;
        width: 100vw;
        flex:1;
      }

.col-reason{
  min-width:400px !important;
  max-width:400px !important
}

.previously-actioned{
  cursor: pointer;
  color: rgb(87, 87, 223) !important
}
#tooltipproduct-disabled > .tooltip-inner {
  max-width: 300px;
  background-color: #303030;
  color: white;
  z-index: 999999;
  position: relative;
  border: 1px solid #303030;
  top:5px;
  font-size: 13px;
}

#tooltipproduct-disabled > .tooltip-arrow {
  border-top: 5px solid #303030;
  z-index: 999999;
  position: relative;
  top:5px;
  color:#303030;
}

#tooltipproduct-disabled > .bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::before {
  border-right-color: #303030;
}
.product_imagecheck{
  bottom: 10px;
  left: 4px;
  position: relative;
}
.recomm-locations::-webkit-scrollbar {display:none}
.location-modal .close {font-size: 2.5rem}

#tooltiplink-disabled > .tooltip-inner {
  max-width: 250px;
  background-color: #303030;
  color: white;
  z-index: 999999;
  position: relative;
  border: 1px solid #303030;
  top:0;
}

#tooltiplink-disabled > .tooltip-arrow {
  border-top: 5px solid #303030;
  z-index: 999999;
  position: relative;
  top:5px;
  color:#303030;
}

#tooltiplink-disabled > .bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #303030;
}
.productdetail-name {font-size: 16px;}
.toastwarning-cls {
  color: #000;
  background-color: #FFF38F;
}
.toastwarning-cls .Toastify__progress-bar {
  background-color: #c6a338;
}
.toastwarning-cls .Toastify__close-button{
  color: #664d03;
}
.bgsosalert-clr .recomm-header {
    background-color: #0056bb;
  }
.disabledActiveActioned {
  pointer-events: none;
  opacity: 0.7;
}
.sosAlertcls .recomm-row {
  border-bottom: none;
}
.sosAlertcls .recomm-row > div.recomm-product {
  height: auto;
  border-bottom: none;
  padding: 10px 20px;
}
@media (max-width: 1680px){
  .alertspagestle .new-recomm .product-name{font-size: 12px; }
  .alertspagestle .productdetail-name {font-size: 12px;font-style: normal;}
  .alertspagestle .productdetail-name {font-size: 12px;font-style: normal;}
  .alertspagestle .recomm-locations div.locations > div.recomm-col .form-check {
        font-size: 12px;
    }
  .alertspagestle  .new-recomm .product-img{
      height: 150px;
      max-width: 150px;
    }
  .alertspagestle .recomm-row > div.recomm-product{
        height:340px;
    }
    .alertspagestle .recomm-row > div.recomm-product.rem-product{
      height:380px;
  }
  .alertspagestle .recom_horizontal {
    min-width: 320px;
    max-width: 320px;
  }  
  .alertspagestle .recomm-header>div.first-col, .recomm-row>div.recomm-product {
      min-width: 320px;
      max-width: 320px;    
  }  
  .alertspagestle .recomm-locations{
    margin-left: 320px;
  }  
  .alertspagestle .recomm-header.recomm-dynamiccol > div.location_block,.alertspagestle .recomm-locations.recomm-dynamiccol div.locations > div.recomm-col.location_block,.alertspagestle .recomm-header.recomm-dynamiccol > div,.recomm-locations.recomm-dynamiccol div.locations > div.recomm-col, .alertspagestle .recomm-header>div.location_block, .recomm-locations div.locations>div.recomm-col.location_block {
      min-width: 210px;
      max-width: 210px;
  }  
  .alertspagestle .recomm-header>div, .recomm-locations div.locations>div.recomm-col {
    min-width: 160px;
    max-width: 160px;
  }
  .alertspagestle .recomm-header>div.strategies, .recomm-row>div.recomm-locations .locations .recomm-col.strategies {
    min-width: 320px;
    max-width: 320px;
  }
  .alertspagestle .recomm-header>div.strategies.all-collapsed {
    min-width: 0;
    max-width: 0;
    width: 0;
    padding: 0;
    border: 0;
    position: relative;
  }
  .alertspagestle .product-sku {
    font-size: 10px;
  }
  .alertspagestle .recomm-dynamiccoldc .recomm-locations {
    margin-left: 450px;
  }
  .alertspagestle .recom_horizontal.recomm-dynamiccoldc {
    min-width: 450px;
    max-width: 450px;
  }
  .alertspagestle .recomm-header.recomm-dynamiccoldc>div.first-col, .alertspagestle .recomm-row.recomm-dynamiccoldc>div.recomm-product {
    min-width: 450px;
    max-width: 450px;
  }
  .alertspagestle .recomm-header.recomm-dynamiccoldc>div, .alertspagestle .recomm-header.recomm-dynamiccoldc>div.location_block, .alertspagestle .recomm-locations.recomm-dynamiccoldc div.locations>div.recomm-col, .alertspagestle .recomm-locations.recomm-dynamiccoldc div.locations>div.recomm-col.location_block {
    min-width: 440px;
    max-width: 440px;
	
}
.sosAlertcls .recomm-row > div.recomm-product {
    height: auto;
    border-bottom: none;
    padding: 10px 20px;
  }
  
}
.sosAlertcls .recomm-locations {height: auto; margin-left: 340px;}
.sosAlertcls .table-responsive {overflow-x: hidden;}

 .alertspagestle .sosAlertcls .recomm-header>div.location_block, .sosAlertcls .recomm-locations div.locations>div.recomm-col.location_block {
  min-width: 280px;
  max-width: 280px;
}
.alertspagestle .sosAlertcls .recom_horizontal, .alertspagestle .sosAlertcls .recomm-header>div.first-col,.sosAlertcls .recomm-row>div.recomm-product {
  min-width: 340px;
  max-width: 340px;
}

.alertspagestle .recom_horizontal.recomm-dynamiccol, .alertspagestle .recomm-header.recomm-dynamiccol > div.first-col, .alertspagestle .recomm-row.recomm-dynamiccol > div.recomm-product {
  min-width: 400px;
  max-width: 400px;
}
.alertspagestle .recomm-dynamiccol .recomm-locations {margin-left: 400px;}

