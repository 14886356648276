#details_wrapper{
   
    .table-bordered td:nth-child(1){
      min-width: 36rem;
    }
    .table-bordered thead td, .table-bordered thead th{
        min-width: 12rem;   
    }
    
   .react-bootstrap-table{
    width: 100%;
    overflow: scroll;
    max-height: 73vh;
   }
 
.table-scroll {
	position:relative;
	overflow:hidden;
}

.table-scroll table {
    width: 100%;
    min-width: 1280px;
    margin: auto;
    border-collapse: separate;
    border-spacing: 0;
  }
  .table-wrap {
    position: relative;
  }
  .table-scroll th,
  .table-scroll td {
    vertical-align: top;
  }
  .table-scroll thead th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }
 
  th{

  }

  th,td:first-child {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 2;
    background: rgb(255, 253, 249);
    color: #3c3f43;
  }

 
  thead th:first-child,
  tfoot th:first-child {
    z-index: 5;
  }
  
  .details_wrapper_reco{
    justify-content: space-between;
}
    
}