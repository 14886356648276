#Updation-modal {
    .btn {
        &.btn-apply {
            font-size: 20px;
            padding: 10px 24px;
        }
    }
    &.modal-dialog {
        max-width: 1150px;
    }
    .newupdatetxt {
        font-weight: bold;
        font-size: 25px;
    }
    .close {
        font-size: 2.5rem;
    }
    .form-control {
        padding: 1.5rem .75rem;
        font-size: 1.3rem;
    }
    .col-form-label {
        font-size: 18px;
        font-weight: bold;
    }
    .selet-clr {
        color: #1563ff;
        text-decoration: underline;
        cursor: pointer;
    }
    .react-datepicker-wrapper{
        width: 33%;
		.col-5{
			max-width: 44.666667%;
		}
	}
    .react-datepicker {
        font-size: 16px;
    }
    .react-datepicker__header {
        width: 330px;
    }
    .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before,
     .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, 
     .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after, 
     .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
        left: -140px
    }
    .react-datepicker-popper {
        z-index: 20;
    }
    .react-datepicker__day-name, 
    .react-datepicker__day,
    .react-datepicker__time-name {
        width: 1.99rem;
        margin: 0.566rem;
    }
    .ck-editor__editable {
		min-height: calc(100vh - 510px) !important;
	}
    .btn_udpates {
        justify-content: center;
    }
    .btn-default {
        border: 1px solid #1563ff;
    }
    .error {
        font-size: 18px;
        padding: 4px;
        text-align: center;
    }    

}

.table thead .Updatetable th {
    background-color: #0f059e;
    color: #fff;
    position: relative;
}
.Updatetable {
    .default-sort-icon {
        color: #fff !important;
        opacity: 0.8;   
        padding-left: 10px;             
        padding-top: 4px;
        right: auto;
    }
}
.Updatepad td {
    padding-left: 12px !important;
    
    
}


.editdelete-cls {
    padding: 0 !important;
    .ed-clr, .dl-clr {   
        border: none;
    }
    .dl-clr {
        border-left: 1px solid #ddd;
        color: red;
    }
}

.ed-clr, .dl-clr {      
    text-decoration: underline;
    cursor: pointer;
    font-weight: bold;
}
.ed-clr {
    color: #1563ff;
}
